const logo = document.getElementsByClassName('has-logo')[0];
const header = document.getElementsByClassName('has-header')[0];


export function changeSizeLogo() {
    if (!header) {
        logo.classList.add("smaller");
    }
    else if (logo && header) {
        logoSmaller();
        window.addEventListener('resize', function () {
            logoSmaller();
        })
    }
 
}

function logoSmaller() {
    const headerHeight = header.offsetHeight;
    let scrollpos = window.scrollY;

    const makeLogoSmaller = () => logo.classList.add("smaller");
    const makeLogoBigger = () => logo.classList.remove("smaller");

    window.addEventListener('scroll', function () {
        scrollpos = window.scrollY;

        if (scrollpos >= headerHeight) {
            makeLogoSmaller();
        } else {
            makeLogoBigger();
        }
    })
}