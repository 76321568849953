export function modal() {
  const modal = document.querySelector(".modal");
  const language =
    window.location.pathname === "/"
      ? "de"
      : window.location.pathname === "/en" ||
        window.location.pathname === "/en/"
      ? "en"
      : "hide";
  if (modal && language != "hide") {
    const modalDE = modal.querySelector(".modal-de");
    const modalEN = modal.querySelector(".modal-en");
    let closeModalBtn;
    if (language == "de") {
      modalEN.style.display = "none";
      closeModalBtn = modalDE.querySelector(".modal-close-btn");
    } else {
      modalDE.style.display = "none";
      closeModalBtn = modalEN.querySelector(".modal-close-btn");
    }

    const body = document.querySelector("body");
    document.documentElement.style.setProperty(
      "--scroll-y",
      `${window.scrollY}px`
    );
    document.documentElement.style.setProperty(
      "--window-width",
      `${document.documentElement.clientWidth}`
    );
    const headerStartpage = language == "de" ? modalDE : modalEN;
    if (!window.sessionStorage.getItem("bannerSeen")) {
      openModal();
    }

    closeModalBtn.onclick = function () {
      closeModal();
    };
    window.onclick = function (event) {
      console.log(event.target);
      if (event.target == headerStartpage) {
        closeModal();
      }
    };
    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    });
    window.addEventListener("resize", () => {
      document.documentElement.style.setProperty(
        "--window-width",
        `${document.documentElement.clientWidth}`
      );
      body.style.width = `${document.documentElement.clientWidth}px`;
    });
    function closeModal() {
      window.sessionStorage.setItem("bannerSeen", "true");
      const scrollY = body.style.top;
      body.classList.remove("modal-is-open");
      modal.style.display = "none";
      body.style.top = "";
      body.style.width = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
    function openModal() {
      const scrollY =
        document.documentElement.style.getPropertyValue("--scroll-y");
      const width =
        document.documentElement.style.getPropertyValue("--window-width");
      modal.style.display = "block";
      body.classList.add("modal-is-open");
      body.style.top = `-${scrollY}`;
      body.style.width = `${width}px`;
    }
  }
}
