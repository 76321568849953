export function loadmore() {
    // console.log('steven_loadmore_params: ', steven_loadmore_params);
    // console.log('steven_loadmore_params.max_page: ', steven_loadmore_params.max_page);
    var button = document.getElementById('loadmore');
    var loading = document.getElementById('loading_animation');
    var container = document.getElementById('container-ajax');
    if (button !== 'undefined' && button !== null) {
        if (steven_loadmore_params.max_page <= steven_loadmore_params.current_page) {
            button.style.display = 'none';
        }

        button.addEventListener('click', function () {
            var beforeSend = function () {
                loading.classList.toggle('is-hidden');
                button.style.display = 'none';
            }
            beforeSend()
            var xhr = new XMLHttpRequest();
            xhr.open('POST', steven_loadmore_params.ajaxurl);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    if (xhr.responseText) {
                        loading.classList.toggle('is-hidden');
                        button.style.display = 'inline-flex';
                        container.innerHTML = container.innerHTML + xhr.response;
                        steven_loadmore_params.current_page++;
                        if (steven_loadmore_params.current_page >= steven_loadmore_params.max_page) {
                            button.style.display = 'none';
                        }
                    } else {
                        button.style.display = 'none';
                    }
                }
            }
            xhr.send("action=loadmore&query=" + encodeURI(steven_loadmore_params.posts) + "&page=" + steven_loadmore_params.current_page)
            return false;
        })
    }
}