import { slidersAnimation, loopTopToBottom, textMedia, sectionFadeBottomToTop, paragraphAnimation, timelineAnimation, textAccordion, hideVideo, hideCountdown } from './floatingbanner';

export function initializeAnimations() {
    slidersAnimation();
    loopTopToBottom();
    textMedia();
    sectionFadeBottomToTop();
    paragraphAnimation();
    timelineAnimation();
    textAccordion();
    hideVideo();
    hideCountdown();
};
