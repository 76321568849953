// import Velocity from "velocity-animate"; use this for complex animation
import { Navigation } from '../js-components/navigation';
import { changeSizeLogo } from '../js-components/changeSizeLogo';
import { initializeSlider } from '../js-components/slider';
import { detailsAccordion } from '../js-components/accordion';
import { loadmore } from '../js-components/loadmore';
import { initializeAnimations } from '../js-components/initializeAnimations';
import { countdown } from '../js-components/countdown';
import { modal } from '../js-components/modal';

export default {
  init() {
    Navigation();
    changeSizeLogo();
    initializeSlider();
    detailsAccordion();
    initializeAnimations();
    loadmore();
    countdown();
    modal();
  }
}
