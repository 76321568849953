import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export function slidersAnimation() {
    const sliderSections = [...document.getElementsByClassName('slider-anim')];

    if (sliderSections.length) {

        sliderSections.map(section => {
            const columns = [...section.getElementsByClassName('place-columns')];
            const slides = [...section.getElementsByClassName('swiper-slide')];
            const boxes = [...section.getElementsByClassName('box')];
            const podcasts = [...section.getElementsByClassName('pocast-item')];

            if (columns) {
                columns.map(column => column.classList.add('slide-animation'));
            } if (slides) {
                slides.map(slide => slide.classList.add('slide-animation'));
            } if (boxes) {
                boxes.map(box => box.classList.add('slide-animation'));
            } if (podcasts) {
                podcasts.map(podcast => podcast.classList.add('slide-animation'));
            }

            gsap.defaults({ ease: "power4", duration: 1.5 });
            gsap.set('.slide-animation', { y: '30%', opacity: 0 });

            ScrollTrigger.batch('.slide-animation', {
                onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.2, overwrite: true }),
            });

        });
    }
};

export function loopTopToBottom() {
    const loopSections = [...document.getElementsByClassName('loop-bottom-animation')];

    if (loopSections.length) {

        loopSections.map(section => {
            const items = [...section.getElementsByClassName('item-animation')];

            if (items) {
                gsap.defaults({ ease: "power4", duration: 1 });
                gsap.set('.item-animation', { y: '-50%', opacity: 0 });

                ScrollTrigger.batch('.item-animation', {
                    onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, duration: 2, stagger: 0.2, overwrite: true }),
                });
            }

        });
    }
};

export function textMedia() {
    const textMediaSections = [...document.getElementsByClassName('text-media-animation')];

    if (textMediaSections.length) {

        textMediaSections.map(section => {
            const columns = section.getElementsByClassName('columns')[0];
            const media = section.getElementsByClassName('slider-media')[0];
            const oneImage = section.getElementsByClassName('has-image')[0];
            const withOverlay = section.getElementsByClassName('overlay-container')[0];

            if (columns && columns.classList.contains('is-desktop-reverse') && !withOverlay) {

                if (media) {

                    gsap.from(media, {
                        scrollTrigger: {
                            trigger: media,
                            start: 'top bottom-=150',
                            end: '+=200',
                        },
                        x: '25%',
                        opacity: 0,
                    });
                } if (oneImage) {

                    gsap.from(oneImage, {
                        scrollTrigger: {
                            trigger: oneImage,
                            start: 'top bottom-=150',
                            end: '+=200',
                        },
                        x: '25%',
                        opacity: 0,
                    });
                }

            } else if (media && !withOverlay) {

                gsap.from(media, {
                    scrollTrigger: {
                        trigger: media,
                        start: 'top bottom-=150',
                        end: '+=200',
                    },
                    x: '-25%',
                    opacity: 0,
                });
            } else if (oneImage) {

                gsap.from(oneImage, {
                    scrollTrigger: {
                        trigger: oneImage,
                        start: 'top bottom-=150',
                        end: '+=200',
                    },
                    x: '-25%',
                    opacity: 0,
                });
            }
        });
    }
};

export function sectionFadeBottomToTop() {
    const animatedSections = [...document.getElementsByClassName('fade-bottom-animation')];

    if (animatedSections.length) {

        animatedSections.map(section => {
            const image = section.getElementsByTagName('img')[0];

            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: 'top bottom-=100',
                    end: '+=200',
                },
                y: '15%',
                duration: 1.5,
            });

            if (image) {
                gsap.from(image, {
                    scrollTrigger: {
                        trigger: section,
                        start: 'top bottom-=100',
                        end: '+=200',
                    },
                    x: '-15%',
                    opacity: 0,
                    duration: 1.5,
                });
            }
        });
    }
};

// export function paragraphAnimation() {
//     const sections = [...document.getElementsByClassName('paragraph-anim')];

//     if (sections.length) {

//         sections.map(sect => {
//             const paragraphs = [...sect.getElementsByTagName('p')];
//             if (paragraphs) {
//                 gsap.from(paragraphs, {
//                     scrollTrigger: {
//                         trigger: sect,
//                         start: 'top bottom-=200',
//                         end: '+=250',
//                     },
//                     y: '30%',
//                     duration: 1.5,
//                 });
//             }
//         });
//     }
// };

// from opacity 0 to 1 außer in den Modals
export function paragraphAnimation() {
    const modals = [...document.getElementsByClassName('modal')];

    if (modals.length) {
        return;

    } else {

        const sections = [...document.getElementsByClassName('paragraph-anim')];

        if (sections.length) {

            sections.map(sect => {
                const paragraphs = [...sect.getElementsByTagName('p')];
                if (paragraphs) {
                    paragraphs.map(paragraph => paragraph.classList.add('paragraph-animation'));

                    gsap.defaults({ ease: "power4", duration: 1.5 });
                    gsap.set('.paragraph-animation', { y: '30%', opacity: 0 });

                    ScrollTrigger.batch('.paragraph-animation', {
                        onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.2, overwrite: true }),
                    });
                }
            });
        }
    }
};

export function timelineAnimation() {
    const sliderSections = [...document.getElementsByClassName('timeline-anim')];

    if (sliderSections.length) {

        sliderSections.map(section => {
            const images = [...section.getElementsByTagName('img')];

            if (images) {
                images.map(img => img.classList.add('timeline-single-anim'));
            }

            gsap.defaults({ ease: "power4", duration: 1.5 });
            gsap.set('.timeline-single-anim', { x: '20%', opacity: 0 });

            ScrollTrigger.batch('.timeline-single-anim', {
                onEnter: batch => gsap.to(batch, { opacity: 1, x: 0, stagger: 0.2, overwrite: true }),
            });

        });
    }
};

export function textAccordion() {
    const textAccordionSections = [...document.getElementsByClassName('text-accord-animation')];

    if (textAccordionSections.length) {

        textAccordionSections.map(section => {
            const columns = section.getElementsByClassName('columns')[0];
            const media = section.getElementsByClassName('has-image')[0];
            if (columns && columns.classList.contains('is-desktop-reverse')) {

                if (media) {

                    gsap.from(media, {
                        scrollTrigger: {
                            trigger: media,
                            start: 'top bottom-=150',
                            end: '+=200',
                        },
                        x: '-25%',
                        opacity: 0,
                    });
                }

            } else if (media) {

                gsap.from(media, {
                    scrollTrigger: {
                        trigger: media,
                        start: 'top bottom-=150',
                        end: '+=200',
                    },
                    x: '25%',
                    opacity: 0,
                });
            }
        });
    }
};

export function hideVideo() {
    const headers = [...document.getElementsByClassName('header-startpage')];

    if (headers.length) {

        headers.map(header => {
            const video = header.getElementsByClassName('video-container')[0];
            //const countdown = header.getElementsByClassName('countdown-container')[0];

            if (video) {
                gsap.to(video, {
                    x: '100%',
                    scrollTrigger: {
                        trigger: header,
                        start: "top+=350 top",
                        duration: 1,
                        toggleActions: "play resume resume reverse",
                        ease: "none",
                    }
                })
            }
            // if (countdown) {
            //     gsap.to(countdown, {
            //         x: '100%',
            //         scrollTrigger: {
            //             trigger: header,
            //             start: "top+=350 top",
            //             duration: 1,
            //             toggleActions: "play resume resume reverse",
            //             ease: "none",
            //         }
            //     })
            // }
        });
    }
}
export function hideCountdown() {
    const countdowns = [...document.getElementsByClassName('countdown-container')];

    if (countdowns.length) {

        countdowns.map(countdown => {
            var mainElement = document.getElementsByTagName("main")[0];
            if (countdown) {
                gsap.to(countdown, {
                    x: '100%',
                    scrollTrigger: {
                        trigger: mainElement,
                        start: "top+=350 top",
                        duration: 1,
                        toggleActions: "play resume resume reverse",
                        ease: "none",
                    }
                })
            }
        });
    }
}