var navbar = document.querySelector(".navbar");
var burger = document.querySelector(".burger");
// var toggleMenu = document.querySelector(".btn-click-effect");
var navMenu = document.querySelector(".navbar-menu");
// var navbarItems = [...document.querySelectorAll('.navbar-item-top')];
var html = document.querySelector("html");

export function Navigation() {
  if (burger) {
    burger.addEventListener("click", function () {
      navbar.classList.toggle("is-active");
      burger.classList.toggle("is-active");
      navMenu.classList.toggle("is-active");
      html.classList.toggle("is-open-menu");
      // toggleMenu.classList.toggle("is-active");
    });

    // close menu on click outside
    document.addEventListener("click", function (e) {
      const isInsideMenu = navMenu.contains(e.target);
      const burgerIsClicked = burger.contains(e.target);

      if (!isInsideMenu && !burgerIsClicked) {
        navbar.classList.remove("is-active");
        burger.classList.remove("is-active");
        navMenu.classList.remove("is-active");
        html.classList.remove("is-open-menu");
      }
    });

    // Js for newsletter form
    setTimeout(() => {
      const privacyCheckbox = document.querySelector(
        ".legal-consent-container .hs-form-booleancheckbox input"
      );
      const checkBox = document.querySelector(".hs-form-booleancheckbox");
      // console.log(checkBox);
      privacyCheckbox.addEventListener("click", function () {
        if (privacyCheckbox.checked) {
          // console.log('checked');
          checkBox.classList.add("gp-checked");
        } else {
          // console.log('unchecked');
          checkBox.classList.remove("gp-checked");
        }
      });
    }, 2000);
  }
}
