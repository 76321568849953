import Swiper from 'swiper/bundle';

export function initializeSlider() {

    // Team slider
    if (document.getElementsByClassName('team-slider').length) {
        [...document.getElementsByClassName('team-slider')].map((teamSlider) => {
            new Swiper('.team-slider .swiper-container', {
                slidesPerView: 1.25,
                spaceBetween: 30,
                freeMode: true,
                preventClicks: false,
                lazy: true,
                scrollbar: {
                    el: ".swiper-scrollbar",
                    hide: false,
                    draggable: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                    1216: {
                        slidesPerView: 4.25,
                    },
                    1300: {
                        slidesPerView: 4.75,
                    },
                    1441: {
                        slidesPerView: 6.25,
                    },
                }
            });
        })
    }

    // Blog slider
    if (document.getElementsByClassName('blog-slider').length) {
        [...document.getElementsByClassName('blog-slider')].map((blogSlider) => {

            new Swiper('.blog-slider .swiper-container', {
                slidesPerView: 1,
                spaceBetween: 30,
                freeMode: true,
                preventClicks: false,
                lazy: true,
                scrollbar: {
                    el: ".swiper-scrollbar",
                    hide: false,
                    draggable: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 1.75,
                    },
                    1024: {
                        slidesPerView: 2,
                    },
                    1216: {
                        slidesPerView: 3,
                    },
                    1300: {
                        slidesPerView: 3,
                    },
                    1441: {
                        slidesPerView: 3,
                    },
                }
            });
        })
    }
    // Module: text + Slider
    if (document.querySelectorAll('.text-media .slider-media').length) {
        [...document.getElementsByClassName('slider-media')].forEach((slider, i) => {
            const pagination = slider.getElementsByClassName('slider-pagination')[0];
            //  slider.classList.add(`slider-media${i}`);
            if (pagination) {
                pagination.classList.add(`slider-media-pagination`);
            }

            new Swiper(`.slider-media .swiper-container`, {
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                loop: true,
                lazy: true,
                pagination: {
                    el: `.slider-media-pagination`,
                    clickable: true,
                    renderBullet: function (i, className) {
                        return '<span class="slider-media-bullet ' + className + '"></span>';
                    },
                },
            });
        })
    }
}

