

import axios from 'axios';
import $ from 'jquery';
import './style.scss';
import Router from './util/Router';
import common from './routes/common';





//import beispiel-seite from './routes/beispiel-seite';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  common
});

/** Load Events */
$(document).ready(() => routes.loadEvents());
